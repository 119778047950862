export default {
	name: "echarte",
	data() {
		return {
			chartInstance: null,
			xAxisData: ["12am", "3", "6", "9", "12pm", "3", "6", "9", "12am"],
			yAxisData1: ["86", " ", "174", "142", "138", "76", "93", "154", "93"],/* 
			yAxisData0: ["126", "208", "264", "242", "238", "106", "193", "254", "193"],
			yAxisData5: ["56", "41", "69", "42", "71", "56", "48", "64", "73"],
			yAxisData3: ["70", "70", "70", "70", "70", "70", "70", "70", "70"],
			yAxisData4: ["180", "180", "180", "180", "180", "180", "180", "180", "180"],
			yAxisData2: ["86", "372", "194", "52", "79"], */
		}
	},
	mounted() {
		this.initchart();
	},
	methods: {
		//初始化echartInstance对象
		initchart() {
			this.chartInstance = this.$echarts.init(this.$refs.seller_ref);
			const initOption = {
				//
				legend: {
					type: 'scroll',
					orient: 'horizontal',
					left: "150px",
					itemWidth: 25,
					itemHeight: 20,
					selectedMode: true,
					icon: "roundRect",
					data: ['最高血糖','平均血糖','最低血糖'],
				},
				xAxis: {
					type: 'category',
					data: this.xAxisData,
					boundaryGap: false,
				},
				yAxis: {
					type: 'value',
					//y轴最大值最小值
					min: 40,
					max: 400,
					//show: false,
					//脱离0起点
					scale: true,
					//间隔400/8=50
					//splitNumber: 4,
				},
				//线的颜色样式
				/* visualMap: {
					top: 500,
					type: 'piecewise',
					//symbolSize: 80,
					pieces: [{
						gt: 40,
						lte: 70,
						color: '#f33131',
					}, {
						gt: 70,
						lte: 180,
						color: '#4870ff',
						
					}, {
						gt: 180,
						lte: 400,
						color: '#fdae61',
					}],
				}, */
				
				series: [
				{
					name: '平均血糖',
					data: this.yAxisData1,
					//线形
					type: 'line',
					//平滑的曲线
					smooth: true,
					//是否显示节点的○符号
					showSymbol: false,
					lineStyle:{
						 color:'#494949'
					 },
					 color:'#494949',
					//分割线
					markLine: {
						symbolSize:[0,0],
						silent: true,
						data: [{
							yAxis: 70,
							lineStyle: {
								color: '#f33131'
							},
						}, {
							yAxis: 180,
							lineStyle: {
								color: '#f1b907'
							},
						}]
					},
					emphasis: {
					    focus: 'series',
					    blurScope: 'coordinateSystem'
					},
				},{
					name: '最高血糖',
					data: this.yAxisData0,
					//线形
					type: 'line',
					lineStyle:{
						 color:'#f33131'
					 },
					 color:'#f33131',
					//平滑的曲线
					smooth: true,
					//是否显示节点的○符号
					showSymbol: false,
					emphasis: {
					    focus: 'series',
					    blurScope: 'coordinateSystem'
					},
				},{
					name: '最低血糖',
					data: this.yAxisData5,
					//线形
					type: 'line',
					left:500,
					lineStyle:{
						 color:'#f1b907'
					 },
					 color:'#f1b907',
					//平滑的曲线
					smooth: true,
					//是否显示节点的○符号
					showSymbol: false,
					emphasis: {
					    focus: 'series',
					    blurScope: 'coordinateSystem'
					},
				},
				],
			};
			this.chartInstance.setOption(initOption)
		},
	},
}
